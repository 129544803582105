import React from "react";
import { useNavigate } from "react-router-dom";
import { S3_BUCKET } from "../../../utils/Constants";
import styles from "./Backbutton.module.scss";

const Backbutton = ({ className }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={`${styles.back_wrap} ${className || ""}`}>
        <button onClick={() => navigate(-1)} className={styles.back_btn}>
          <img src={`${S3_BUCKET.IMAGES}/back-icon.png`} alt="back-icon" />
          Back
        </button>
      </div>
    </>
  );
};

export default Backbutton;
