import React, { useState } from 'react';
import styles from "./Footer.module.scss";
import { S3_BUCKET, SOCIAL_MEDIA } from '../../../utils/Constants';

import {
    DiscordIcon,
    FacebookIcon,
    GithubIcon,
    InstagramIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
} from "../../../assets/svg/svgicons";
import { Link } from 'react-router-dom';
import SiteDescription from '../Modals/SiteDescription/SiteDescription';
import SatyugMeaning from '../Modals/SatyugMeaning/SatyugMeaning';
const Footer = ({ className }) => {
    const [show, setShow] = useState(false);
    const [showMeaning, setShowMeaning] = useState(false);
    return (
        <>
            <div className={`${styles.home_footer} ${className || ""}`}>
                <div className="d-sm-flex mx-sm-0 mx-auto pe-4 align-items-center">
                    <h3 onClick={() => setShowMeaning(true)} className='clickable'>
                        {/* <img src={S3_BUCKET.DIGITAL_LOGO} alt="logo" /> */}
                        <img src={S3_BUCKET.OLD_LOGO} alt="logo" />
                        Satyug</h3>
                    <ul className={styles.footer_social}>
                        <li>
                            <a
                                href={SOCIAL_MEDIA.TWITTER}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <TwitterIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href={SOCIAL_MEDIA.GITHUB}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <GithubIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href={SOCIAL_MEDIA.DISCORD}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <DiscordIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href={SOCIAL_MEDIA.REDDIT}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <RedditIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href={SOCIAL_MEDIA.TELEGRAM}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <TelegramIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href={SOCIAL_MEDIA.TELEGRAM}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <InstagramIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href={SOCIAL_MEDIA.TELEGRAM}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FacebookIcon />
                            </a>
                        </li>
                    </ul>
                </div>
                <ul className={styles.footer_right}>
                    <li>
                        <Link target="_blank" rel="noopener noreferrer" to={SOCIAL_MEDIA.WHITEPAPER}>
                            Satyug Whitepaper
                        </Link>
                    </li>
                    <li>
                        <Link target="_blank" rel="noopener noreferrer" to={SOCIAL_MEDIA.LIGHTPAPER}>Satyug Pitch Deck</Link>
                    </li>
                    <li>
                        <Link
                            to="/contact"
                        >
                            Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link
                            to=""
                            onClick={e => { e.preventDefault(); setShow(true) }}
                        >
                            About Satyug
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/privacy-policy"
                        >
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/terms-services"
                        >
                            Terms of Services
                        </Link>
                    </li>
                </ul>
            </div>
            <SiteDescription show={show} handleClose={() => setShow(false)} />
            <SatyugMeaning show={showMeaning} handleClose={() => setShowMeaning(false)} />
        </>
    )
}

export default Footer
