const ENV = process.env;
export const ENVIRONMENT = {
  RAZORPAY_KEY: ENV.REACT_APP_RAZORPAY_KEY,
  RAZORPAY_KEY_DONATION: ENV.REACT_APP_RAZORPAY_KEY_DONATION,
  MAP_key: ENV.REACT_APP_GOOGLE_API_KEY,
  GAME_URL_RAM_MANDIR_NIRMAN: ENV.REACT_APP_GAME_URL_RAM_MANDIR_NIRMAN,
  GAME_URL_MANDIR_DARSHAN: ENV.REACT_APP_GAME_URL_MANDIR_DARSHAN,
  GAME_URL_PRABHU_SHINGAR: ENV.REACT_APP_GAME_URL_PRABHU_SHINGAR,
  GAME_URL_BHOJNALYA: ENV.REACT_APP_GAME_URL_BHOJNALYA,
  GAME_URL_BAGICHA: ENV.REACT_APP_GAME_URL_BAGICHA,
  RAZORPAY_URL: ENV.REACT_APP_RAZORPAY,
  URL_LINK: ENV.REACT_APP_SHARE_URL
};
// export const URL_LINK = `https://www.stage-user.satyug.life/?id=`
export const FORMIK_REGEX = {
  ALPHA_REGEX: /^[A-Za-z\s]+$/,
  MOBILE_NUMBER_REGEX: /^\+(?:[0-9]●?){6,16}[0-9]$/,
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EMAIL_REGEX_NEW:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ADDRESS_REGEX: /^[a-zA-Z a-zA-Z0-9\n-/@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/,
  POSTAL_REGEX: /^(\d{4}|\d{10})$/,
  NUMERIC_REGEX: /^\d+$/,
};
export const GAME_TOKEN = {
  GameAccessToken: "GameAccessToken",
  GameVerifyToken: "GameVerifyToken",
  UserRegisterToken: "UserRegisterToken",
  Collectible: "collectible",
};

export const S3_BUCKET = {
  URL: "https://dvf7opio6knc7.cloudfront.net",
  IMAGESLOGO: "https://dvf7opio6knc7.cloudfront.net/digital-logo1.png",
  IMAGES: "https://dvf7opio6knc7.cloudfront.net/satyugImages",
  VIDEOS: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos",
  SHIRT: "https://dvf7opio6knc7.cloudfront.net/products/Tshirt.png",
  CAP: "https://dvf7opio6knc7.cloudfront.net/satyug/products/cap.png",
  BEFORE_GAME: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/BeforeGam.mp4",
  CINEMATIC_GAME: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/Construction.mp4",
  DAKSHINA_GAME: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/Bhojnalya_stage.mp4",
  SATHAPNA_GAME: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/Murti_stage.mp4", 
  NIRMAAN_GAME: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/bagicha-stage.mp4",
  ABOUT_US_TEXT: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/about-us.png",
  CLICK_TO_ENTER_TEXT: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/click-to-enter.png",
  SATYUG_VIDEO_POSTER: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/satyug-video-poster.webp",
  SHARE_PAGE_VIDEO: "https://dvf7opio6knc7.cloudfront.netio/satyug/satyugvideos/BahumulyaWEBM.webm",
  USER_NOT_EXIST_VIDEO: "https://dvf7opio6knc7.cloudfront.net/video_v2/02.webm",
  BAGEECHA: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/bageecha.webp",
  MURTI_STHAPANA: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/murthi-sathapna.webp",
  BHOJNALYA: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/bhojnalaya.webp",
  MANDIR: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/card-image.webp",
  SHARE_IMAGE: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/share-image.webp",
  ABOUT_US_HIGH: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/about-us.mp4",
  ABOUT_US_LOW: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/about-us-low.mp4",
  ABOUT_US_480: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/about-us-480.mp4",
  ABOUT_US_720: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/about-us-720.mp4",
  SATYUG_VIDEO: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/Satyug.mp4",
  SATYUG_VIDEO_LOW: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/satyug-low.mp4",
  DHYANA_VIDEO: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/dhyana.mp4",
  DHYANA_VIDEO_LOW: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/dhyana-low.mp4",
  YOGA_VIDEO: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/yoga.mp4",
  YOGA_VIDEO_LOW: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/yoga-low.mp4",
  SHARE_BACK: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugvideos/sharevideo.mp4",
  ABOUT_US_POSTER: "https://dvf7opio6knc7.cloudfront.net/satyug/satyugImages/about-us-poster.png",
  LOGO_HD: "https://dvf7opio6knc7.cloudfront.net/satyugImages/logo-hd.webp",
  BEFORE_GAME_720: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/before-game-720.mp4",
  BEFORE_GAME_480: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/before-game-480.mp4",
  BEFORE_GAME_360: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/before-game-360.mp4",
  SHARE_360: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/share-360.mp4",
  SHARE_480: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/share-480.mp4",
  OLD_LOGO: "https://dvf7opio6knc7.cloudfront.net/satyugImages/satyug-old-logo.webp",
  BADHAI: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/Badhai (1).webm",
  DIGITAL_LOGO: "https://dvf7opio6knc7.cloudfront.net/satyugImages/digital-logo.png",
  PRABHU_SHINGAR: "https://dvf7opio6knc7.cloudfront.net/video_v2/webm/prabhu_shingaar.webm",
  BAGICHA_NIRMAN: "https://dvf7opio6knc7.cloudfront.net/video_v2/webm/bagicha_nirmaan.webm",
  BHOJNALYA_YOGDAN: "https://dvf7opio6knc7.cloudfront.net/video_v2/webm/Aap_ka_yogdaan.webm",
  WELCOME_FLAG: "https://dvf7opio6knc7.cloudfront.net/satyugImages/welcome-flag.png",
  WELCOME_LEFT_ARROW: "https://dvf7opio6knc7.cloudfront.net/satyugImages/welcome-arrow-left.png",
  WELCOME_RIGHT_ARROW: "https://dvf7opio6knc7.cloudfront.net/satyugImages/welcome-flag-right.png",
  UP_BOW: "https://dvf7opio6knc7.cloudfront.net/satyugImages/up-bow.png",
};
export const S3_BUCKET_AUDIO = {
  AFTER_GAME: "https://dvf7opio6knc7.cloudfront.net/audio/AfterGame.mp3",
  DONATE: "https://dvf7opio6knc7.cloudfront.net/audio/Donate.mp3",
  MANDIR_CONSTRUCTION:
    "https://dvf7opio6knc7.cloudfront.net/audio/MandirConstruction.mp3",
  QUIZ: "https://dvf7opio6knc7.cloudfront.net/audio/Quiz.mp3",
  SHARE: "https://dvf7opio6knc7.cloudfront.net/audio/share.mp3",
  PARTICIPATION: "https://dvf7opio6knc7.cloudfront.net/audio/participation.mp3"
};

export const NETWORK_SPEED = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
}


export const SCREEN_SIZE = {
  MOBILE: "MOBILE",
  TAB: "TAB",
  DESKTOP: "DESKTOP",
}

export const SOCIAL_MEDIA = {
  TWITTER: "https://twitter.com/SatyugLife",
  GITHUB: "https://github.com/Satyug-life/Satyug-website",
  DISCORD: "https://discord.gg/Tg5m53ah",
  REDDIT: "https://www.reddit.com/r/satyug/",
  TELEGRAM: "https://t.me/+h5ablpKtH39mYzg1",
  WHITEPAPER: "https://satyug-bucket.s3.amazonaws.com/satyugImages/Satyug Whitepaper.pdf",
  LIGHTPAPER: "https://satyug-bucket.s3.amazonaws.com/satyugImages/Satyug%20Lightpaper.pdf",
  FACEBOOK: "https://www.facebook.com/DigitalRamMandir?mibextid=ZbWKwL",
  INSTAGRAM: "https://www.instagram.com/digitalrammandir?utm_source=qr&igshid=YTlmZjI0ZWMzOA==",
}

export const getPriceForType = (type) => {
  switch (type) {
    case 1:
      return 50;
    case 2:
      return 100;
    case 3:
      return 200;
    case 4:
      return 2100;
    default:
      // Handle unknown types or provide a default value
      return 0;
  }
};

export const productType = (type) => {
  switch (type) {
    case 1:
      return 't-shirt';
    case 2:
      return 'cap';
    default:
      return 'unknown';
  }
};
