import React, { useEffect, useState } from 'react'
import { Container } from "react-bootstrap";
import styles from "./ThalliStatus.module.scss";
import Backbutton from '../../common/Backbutton/Backbutton';
import HeadingText from '../../common/HeadingText/HeadingText';
import { S3_BUCKET } from '../../../utils/Constants';
import { apiCallGet } from '../../../axiosApi/Axios';
import { useQuery } from "../../../hooks/useQuery";
import moment from 'moment';


const ThalliStatus = () => {
    const [data ,setData]= useState()
  const query = useQuery();

  const id = query.get('userId')
    

    const getThaliStatus = async () => {
        try {
          let response = await apiCallGet(
            `/api/v1/satyug/orders/thali/${id}/status`,
            {},
            true,
            false
          );
    
          if (response.status === 200) {
            setData(response?.data);
    
          }
        } catch (error) {
          console.log(error);
        }
      };
   
    useEffect(() =>{
        if(id){
        getThaliStatus()
        }
    },[id])



    return (
        <>
            <section className={styles.status_page}>
                <Container>
                    <Backbutton />
                    <HeadingText
                        heading={"Physical Pooja ka Option Select karne ke liye aap ka dhanyawad "}
                        className={styles.heading}
                    />
                    <div className={styles.box}>
                        <img src={S3_BUCKET.UP_BOW} alt="bow" className={styles.bow} />
                        <img src={S3_BUCKET.DIGITAL_LOGO} alt="logo" className={styles.logo} />
                        <ul>
                            <li>
                                <h4>Date & Time of purchase:</h4>
                                <p>{moment(data?.createdAt).format('DD-MM-YYYY') || "--"}</p>
                               
                            </li>
                            <li>
                                <h4>Status Of Delivery:</h4>
                                <p>{data?.ThaliStatus}</p>
                            </li>
                        </ul>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default ThalliStatus
