import ContactUs from "../components/pages/ContactUs/ContactUs";
import Games from "../components/pages/Games/Games";
import PrivacyPolicy from "../components/pages/PrivacyPolicy/PrivacyPolicy";
import TermServices from "../components/pages/TermServices/TermServices";
import ThalliStatus from "../components/pages/ThalliStatus/ThalliStatus";


const routes = [
  {
    // path: "activity",
    path: "/",
    element: <Games />,
  },
  {
    path: "contact",
    element: <ContactUs />
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "terms-services",
    element: <TermServices />,
  },
  {
    path: "thalli-status",
    element: <ThalliStatus />,
  }
];


export default routes;
